var isModalOpen = false;
var modalInterval;
// var apiUrl = "/api";
var apiUrl = "";

var renderStructure = {
  renderModalStructure: function ({
    animate,
    type,
    lotteryType,
    currentDate,
    lotteryName,
    id,
    nextDrawTime,
    date,
    result,
    headTail,
  }) {
    return `
      <div class="modal ${type} ${animate ? "animate" : ""}">
        <div class="modal-wrapper">
          <div class="modal-header">
            <h3 class="modal-title" data-lang-text="${type}ern Flash Lottos">${type}ern Flash Lottos</h3>
            <button class="close">Close</button>
          </div>
          <div class="modal-content">
            <div class="main-content result-item" data-lottery-type="${lotteryType}" data-type="${type}">
              <div class="main-content-header">
                <div class="lottery-name-wrapper" data-lang-text="${lotteryName} Lotto">${
                  type === "north"
                    ? `<img src='../img/northern.png' />`
                    : `<img src='../img/southern.png' />`
                } ${lotteryName}</div>
                <div class="date-picker-wrapper" data-toggle="datepicker">
                (<span class="span-date">${
                  currentDate ? currentDate : date
                }</span>)
                    <div class="icon-calendar"></div>
                </div>
              </div>
              <div class="result-wrapper">
                <div class="result-table">
                  <div class="result-row">
                    <div class="text drawnumber">ID: ${id}</div>
                  </div>
                  ${
                    type === "north"
                      ? `
                    <div class="result-row">
                      <div class="label" data-lang-text="SPL">SPL</div>
                      <div class="text"><span class="special">${
                        result["special"][0]
                      }</span></div>
                   </div>
                   <div class="result-row">
                      <div class="label" data-lang-text="1st">1<sup>st</sup></div>
                      <div class="text"><span>${result["1st"][0]}</span></div>
                    </div>
                    <div class="result-row">
                      <div class="label" data-lang-text="2nd">2<sup>nd</sup></div>
                      <div class="text"><span>${result["2nd"][0]}</span></div>
                      <div class="text"><span>${
                        result["2nd"][1] ? result["2nd"][1] : ""
                      }</span></div>
                    </div>
                    <div class="result-row">
                      <div class="label" data-lang-text="3rd">3<sup>rd</sup></div>
                      <div class="text"><span>${result["3rd"][0]}</span></div>
                      <div class="text"><span>${result["3rd"][1]}</span></div>
                      <div class="text"><span>${
                        result["3rd"][2] ? result["3rd"][2] : ""
                      }</span></div>
                    </div>
                    <div class="result-row">
                      <div class="label"></div>
                      <div class="text"><span>${
                        result["3rd"][3] ? result["3rd"][3] : ""
                      }</span></div>
                      <div class="text"><span>${
                        result["3rd"][4] ? result["3rd"][4] : ""
                      }</span></div>
                      <div class="text"><span>${
                        result["3rd"][5] ? result["3rd"][5] : ""
                      }</span></div>
                    </div>
                    <div class="result-row">
                      <div class="label" data-lang-text="4th">4<sup>th</sup></div>
                      <div class="text"><span>${result["4th"][0]}</span></div>
                      <div class="text"><span>${result["4th"][1]}</span></div>
                    </div>
                    <div class="result-row">
                      <div class="label"></div>
                        <div class="text"><span>${result["4th"][2]}</span></div>
                      <div class="text"><span>${result["4th"][3]}</span></div>
                    </div>
                    <div class="result-row">
                      <div class="label" data-lang-text="5th">5<sup>th</sup></div>
                      <div class="text"><span>${result["5th"][0]}</span></div>
                      <div class="text"><span>${
                        result["5th"][1] ? result["5th"][1] : ""
                      }</span></div>
                      <div class="text"><span>${
                        result["5th"][2] ? result["5th"][2] : ""
                      }</span></div>
                    </div>
                    <div class="result-row">
                      <div class="label"></div>
                      <div class="text"><span>${
                        result["5th"][3] ? result["5th"][3] : ""
                      }</span></div>
                      <div class="text"><span>${
                        result["5th"][4] ? result["5th"][4] : ""
                      }</span></div>
                      <div class="text"><span>${
                        result["5th"][5] ? result["5th"][5] : ""
                      }</span></div>
                    </div>
                    <div class="result-row">
                      <div class="label" data-lang-text="6th">6<sup>th</sup></div>
                      <div class="text"><span>${result["6th"][0]}</span></div>
                      <div class="text"><span>${result["6th"][1]}</span></div>
                      <div class="text"><span>${result["6th"][2]}</span></div>
                    </div>
                    <div class="result-row">
                      <div class="label" data-lang-text="7th">7<sup>th</sup></div>
                      <div class="text"><span>${result["7th"][0]}</span></div>
                      <div class="text"><span>${
                        result["7th"][1] ? result["7th"][1] : ""
                      }</span></div>
                      <div class="text"><span>${
                        result["7th"][2] ? result["7th"][2] : ""
                      }</span></div>
                      <div class="text"><span>${
                        result["7th"][3] ? result["7th"][3] : ""
                      }</span></div>
                    </div>
                  `
                      : `
                    <div class="result-row">
                      <div class="label" data-lang-text="8th">8<sup>th</sup></div>
                      <div class="text"><span>${
                        result["8th"] ? result["8th"][0] : ""
                      }</span></div>
                    </div>
                    <div class="result-row">
                      <div class="label" data-lang-text="7th">7<sup>th</sup></div>
                      <div class="text"><span>${result["7th"][0]}</span></div>
                    </div>
                    <div class="result-row">
                      <div class="label" data-lang-text="6th">6<sup>th</sup></div>
                      <div class="text"><span>${result["6th"][0]}</span></div>
                      <div class="text"><span>${result["6th"][1]}</span></div>
                      <div class="text"><span>${result["6th"][2]}</span></div>
                    </div>
                    <div class="result-row">
                      <div class="label" data-lang-text="5th">5<sup>th</sup></div>
                      <div class="text"><span>${result["5th"][0]}</span></div>
                    </div>
                    <div class="result-row">
                      <div class="label" data-lang-text="4th">4<sup>th</sup></div>
                      <div class="text"><span>${result["4th"][0]}</span></div>
                      <div class="text"><span>${result["4th"][1]}</span></div>
                      <div class="text"><span>${result["4th"][2]}</span></div>
                    </div>
                    <div class="result-row">
                      <div class="label"></div>
                      <div class="text"><span>${result["4th"][3]}</span></div>
                      <div class="text"><span>${
                        result["4th"][4] ? result["4th"][4] : ""
                      }</span></div>
                      <div class="text"><span>${
                        result["4th"][5] ? result["4th"][5] : ""
                      }</span></div>
                    </div>
                    <div class="result-row">
                      <div class="label"></div>
                      <div class="text"><span>${
                        result["4th"][6] ? result["4th"][6] : ""
                      }</span></div>
                    </div>
                    <div class="result-row">
                      <div class="label" data-lang-text="3rd">3<sup>rd</sup></div>
                      <div class="text"><span>${result["3rd"][0]}</span></div>
                      <div class="text"><span>${result["3rd"][1]}</span></div>
                    </div>
                    <div class="result-row">
                      <div class="label" data-lang-text="2nd">2<sup>nd</sup></div>
                      <div class="text"><span>${result["2nd"][0]}</span></div>
                    </div>
                    <div class="result-row">
                      <div class="label" data-lang-text="1st">1<sup>st</sup></div>
                      <div class="text"><span>${result["1st"][0]}</span></div>
                    </div>
                    <div class="result-row">
                      <div class="label" data-lang-text="SPL">SPL</div>
                      <div class="text"><span class="special">${
                        result["special"][0]
                      }</span></div>
                    </div>
                    `
                  }
                </div>
                <div class="result-table head-tail">
                  <div class="result-row">
                    <div class="label" data-lang-text="Head">Head</div>
                    <div class="text" data-lang-text="Tail">Tail</div>
                  </div>
                  ${Object.entries(headTail)
                    .map(function (item, index) {
                      var label = item[0];
                      var text = item[1];
                      var special = headTail["special"].split(",");
                      var head = special[0];
                      var tail = special[1];

                      var resultRow = "";
                      var renderedSpecial = false;

                      if (item[0] !== "special") {
                        resultRow = `
                        <div class="result-row">
                          <div class="label" data-lang-text="${label}">${label}</div>
                          <div class="text">
                            ${text.split(",").map(function (val, index) {
                              var textItem = val;

                              if (textItem === "") {
                                textItem = "-";
                              }

                              if (
                                label.toString() === head.toString() &&
                                val.toString() === tail.toString() &&
                                !renderedSpecial
                              ) {
                                textItem = `<span class="special" data-lang-text="${textItem}">${textItem}</span>`;
                                renderedSpecial = true;
                              }
                              return textItem;
                            })}
                          </div>
                        </div>
                        `;
                      }
                      return resultRow;
                    })
                    .join("")}
                </div>
              </div>
            </div>
            <div class="sidebar">
              <div class="sidebar-title">
                <span class="dropdown-toggle">
                  <strong data-lang-text="${lotteryName} Lotto">${lotteryName} Lotto</strong>
                  <div class="dropdown">
                    <div class="dropdown-title">- Northern Lotto -</div>
                    <a class="dropdown-item" href="#game=north-45-sec" data-lang-text="North 45 Sec Lotto">North 45 Sec Lotto</a
                    >
                    <a class="dropdown-item" href="#game=north-60-sec" data-lang-text="North 60 Sec Lotto">North 60 Sec Lotto</a
                    >
                    <a class="dropdown-item" href="#game=north-90-sec" data-lang-text="North 90 Sec Lotto">North 90 Sec Lotto</a
                    >
                    <a class="dropdown-item" href="#game=north-2-min" data-lang-text="North 2 Min Lotto">North 2 Min Lotto</a
                    >
                    <a class="dropdown-item" href="#game=north-5-min" data-lang-text="North 5 Min Lotto">North 5 Min Lotto</a
                    >
                    <div class="dropdown-title" data-lang-text="Southern Lotto">- Southern Lotto -</div>
                    <a class="dropdown-item" href="#game=south-45-sec" data-lang-text="South 45 Sec Lotto">South 45 Sec Lotto</a
                    >
                    <a class="dropdown-item" href="#game=south-60-sec" data-lang-text="South 60 Sec Lotto">South 60 Sec Lotto</a
                    >
                    <a class="dropdown-item" href="#game=south-90-sec" data-lang-text="South 90 Sec Lotto">South 90 Sec Lotto</a
                    >
                    <a class="dropdown-item" href="#game=south-2-min" data-lang-text="South 2 Min Lotto">South 2 Min Lotto</a
                    >
                    <a class="dropdown-item" href="#game=south-5-min" data-lang-text="South 5 Min Lotto">South 5 Min Lotto</a
                    >
                  </div>
                </span>
              </div>
              <div class="countdown">
                <div class="countdown-label" data-lang-text="Next Draw">Next Draw</div>
                <div class="time" id="sidebar-time">${nextDrawTime}</div>
              </div>
              <div class="sidebar-content"></div>
            </div>
          </div>
        </div>
      </div>
    `;
  },
  renderBigResultItem: function ({
    title,
    type,
    lotteryType,
    lotteryId,
    lotteryDate,
    lotteryDrawTime,
    lotteryNextDrawTime,
    serverTime,
    lotteryResult,
  }) {
    return `
  <div class="result-item" data-lottery-type="${lotteryType}" data-type="${type}">
    <div class="result-item-header">
      <h3 class="title" data-lang-text="${title}">${title}</h3>
      <div class="draw-time" data-lang-text="Next Draw">
        Next Draw
        <div class="time-counter"></div>
      </div>
    </div>
    <div class="result-item-content">
      <div class="result-ball">
      ${lotteryResult["special"]
        .map(function (special) {
          return special
            .split("")
            .map(function (numbers) {
              return `<div class="ball">${numbers}</div>`;
            })
            .join("");
        })
        .join("")}
      </div>
      <div class="result-info">
        <div class="drawnumber">ID: ${lotteryId}</div>
        <a class="action" href="#game=${type}-${lotteryType}" data-lang-text="See All">See All</a>
      </div>
      
      ${
        type === "north"
          ? `
        <div class="result-table">
          <div class="result-row">
            <div class="label" data-lang-text="SPL">SPL</div>
            <div class="text"><span class="special">${lotteryResult["special"][0]}</span></div>
          </div>
          <div class="result-row">
            <div class="label" data-lang-text="1st">1<sup>st</sup></div>
            <div class="text">${lotteryResult["1st"][0]}</div>
          </div>
          <div class="result-row">
            <div class="label" data-lang-text="2nd">2<sup>nd</sup></div>
            <div class="text">${lotteryResult["2nd"][0]}</div>
            <div class="text">${lotteryResult["2nd"][1]}</div>
          </div>
          <div class="result-row">
            <div class="label" data-lang-text="3rd">3<sup>rd</sup></div>
            <div class="text">${lotteryResult["3rd"][0]}</div>
            <div class="text">${lotteryResult["3rd"][1]}</div>
            <div class="text">${lotteryResult["3rd"][2]}</div>
          </div>
          <div class="result-row">
            <div class="label"></div>
            <div class="text">${lotteryResult["3rd"][3]}</div>
            <div class="text">${lotteryResult["3rd"][4]}</div>
            <div class="text">${lotteryResult["3rd"][5]}</div>
          </div>
          <div class="result-row">
            <div class="label" data-lang-text="4th">4<sup>th</sup></div>
            <div class="text">${lotteryResult["4th"][0]}</div>
            <div class="text">${lotteryResult["4th"][1]}</div>
          </div>
          <div class="result-row">
            <div class="label"></div>
            <div class="text">${lotteryResult["4th"][2]}</div>
            <div class="text">${lotteryResult["4th"][3]}</div>
          </div>
          <div class="result-row">
            <div class="label" data-lang-text="5th">5<sup>th</sup></div>
            <div class="text">${lotteryResult["5th"][0]}</div>
            <div class="text">${lotteryResult["5th"][1]}</div>
            <div class="text">${lotteryResult["5th"][2]}</div>
          </div>
          <div class="result-row">
            <div class="label"></div>
            <div class="text">${lotteryResult["5th"][3]}</div>
            <div class="text">${lotteryResult["5th"][4]}</div>
            <div class="text">${lotteryResult["5th"][5]}</div>
          </div>
          <div class="result-row">
            <div class="label" data-lang-text="6th">6<sup>th</sup></div>
            <div class="text">${lotteryResult["6th"][0]}</div>
            <div class="text">${lotteryResult["6th"][1]}</div>
            <div class="text">${lotteryResult["6th"][2]}</div>
          </div>
          <div class="result-row">
            <div class="label" data-lang-text="7th">7<sup>th</sup></div>
            <div class="text">${lotteryResult["7th"][0]}</div>
            <div class="text">${lotteryResult["7th"][1]}</div>
            <div class="text">${lotteryResult["7th"][2]}</div>
            <div class="text">${lotteryResult["7th"][3]}</div>
          </div>
        </div>
        `
          : `
        <div class="result-table">
          <div class="result-row">
            <div class="label" data-lang-text="8th">8<sup>th</sup></div>
            <div class="text">${lotteryResult["8th"][0]}</div>
          </div>
          <div class="result-row">
            <div class="label" data-lang-text="7th">7<sup>th</sup></div>
            <div class="text">${lotteryResult["7th"][0]}</div>
          </div>
          <div class="result-row">
            <div class="label" data-lang-text="6th">6<sup>th</sup></div>
            <div class="text">${lotteryResult["6th"][0]}</div>
            <div class="text">${lotteryResult["6th"][1]}</div>
            <div class="text">${lotteryResult["6th"][2]}</div>
          </div>
          <div class="result-row">
            <div class="label" data-lang-text="5th">5<sup>th</sup></div>
            <div class="text"></div>
            <div class="text">${lotteryResult["5th"][0]}</div>
            <div class="text"></div>
          </div>
          <div class="result-row">
            <div class="label" data-lang-text="4th">4<sup>th</sup></div>
            <div class="text">${lotteryResult["4th"][0]}</div>
            <div class="text">${lotteryResult["4th"][1]}</div>
            <div class="text">${lotteryResult["4th"][2]}</div>
          </div>
          <div class="result-row">
            <div class="label"></div>
            <div class="text">${lotteryResult["4th"][3]}</div>
            <div class="text">${lotteryResult["4th"][4]}</div>
            <div class="text">${lotteryResult["4th"][5]}</div>
          </div>
          <div class="result-row">
            <div class="label"></div>
            <div class="text"></div>
            <div class="text">${lotteryResult["4th"][6]}</div>
            <div class="text"></div>
          </div>
          <div class="result-row">
            <div class="label" data-lang-text="3rd">3<sup>rd</sup></div>
            <div class="text">${lotteryResult["3rd"][0]}</div>
            <div class="text">${lotteryResult["3rd"][1]}</div>
          </div>
          <div class="result-row">
            <div class="label" data-lang-text="2nd">2<sup>nd</sup></div>
            <div class="text">${lotteryResult["2nd"][0]}</div>
          </div>
          <div class="result-row">
            <div class="label" data-lang-text="1st">1<sup>st</sup></div>
            <div class="text">${lotteryResult["1st"][0]}</div>
          </div>
          <div class="result-row">
            <div class="label" data-lang-text="SPL">SPL</div>
            <div class="text"><span class="special">${lotteryResult["special"][0]}</span></div>
          </div>
        </div>
        `
      }
      
    </div>
  </div>
`;
  },
  renderSmallResultItem: function ({
    title,
    type,
    lotteryType,
    lotteryId,
    lotteryDate,
    lotteryDrawTime,
    lotteryNextDrawTime,
    serverTime,
    lotteryResult,
  }) {
    return `
    <div class="result-item" data-lottery-type="${lotteryType}" data-type="${type}">
      <div class="result-item-header">
        <h3 class="title" data-lang-text="${title}">${title}</h3>
        <div class="draw-time" data-lang-text="Next Draw">
          Next Draw
          <div class="time-counter"></div>
        </div>
      </div>
      <div class="result-item-content">
        <div class="result-ball">
         ${lotteryResult["special"]
           .map(function (special) {
             return special
               .split("")
               .map(function (numbers) {
                 return `<div class="ball">${numbers}</div>`;
               })
               .join("");
           })
           .join("")}
        </div>
        <div class="result-info">
          <div class="drawnumber">ID: ${lotteryId}</div>
         <a class="action" href="#game=${type}-${lotteryType}" data-lang-text="See All">See All</a>
        </div>
      </div>
    </div>
  `;
  },
  renderModalPastResult: function (data) {
    var currentId = util.getUrlParameter("id");
    var currentDate = util.getUrlParameter("date");

    /**
     * Update footer navigation based on current result
     */

    var nextGameId =
      data.indexOf(currentId) === data.length
        ? null
        : data[data.indexOf(currentId) + 1];

    var prevGameId =
      data.indexOf(currentId) === 0 ? null : data[data.indexOf(currentId) - 1];

    if (nextGameId) {
      $(".modal-content .page-nav .next")
        .attr(
          "href",
          `#game=${util.getUrlParameter("game")}&id=${nextGameId}${
            currentDate ? "&date=" + currentDate : ""
          }`
        )
        .removeClass("disabled");
    } else {
      $(".modal-content .page-nav .next")
        .attr("href", "#!")
        .addClass("disabled");
    }

    if (prevGameId) {
      $(".modal-content .page-nav .prev")
        .attr(
          "href",
          `#game=${util.getUrlParameter("game")}&id=${prevGameId}${
            currentDate ? "&date=" + currentDate : ""
          }`
        )
        .removeClass("disabled");
    } else {
      $(".modal-content .page-nav .prev")
        .attr("href", "#!")
        .addClass("disabled");
    }

    return `
      ${data
        .map(function (val, index) {
          return `<a class="sidebar-lottery-item ${
            currentId && currentId === val
              ? "active"
              : index === 0 && !currentId
              ? "active"
              : ""
          }" href="#game=${util.getUrlParameter("game")}&id=${val}${currentDate ? "&date=" + currentDate : ""}">ID: ${val}</a>`;
        })
        .join("")}
    `;
  },
};

var util = {
  getUrlParameter: function (name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\#&]" + name + "=([^&#]*)");
    var results = regex.exec(location.hash);

    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  },
  disableScroll: function () {
    const scrollY = document.documentElement.style.getPropertyValue(
      "--scroll-y"
    );
    const body = document.body;
    body.style.position = "fixed";
    body.style.top = `-${scrollY}`;
    body.style.width = `100%`;
  },
  enableScroll: function () {
    const body = document.body;
    const scrollY = body.style.top;
    body.style.position = "";
    body.style.top = "";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
  },
  hashDetection: function () {
    renderIfHash();
    $(window).on("hashchange", function () {
      renderIfHash();
    });
  },
};

var api = {
  lastResult: function () {
    $.ajax({
      url: apiUrl + "/data/lastResult.json",
      success: function (data) {
        renderHome(data);
      },
      error: function (err) {
        // alert(err);
      },
    });
  },
  result: function ({ currentHash, id, date, target }) {
    $.ajax({
      url: apiUrl + "/data/result.json",
      data: {
        game: currentHash,
        id,
        date,
      },
      success: function (data) {
        if (target) {
          renderSingleItem(data, target);
        } else {
          renderModal(data, currentHash);
        }
      },
      error: function (err) {
        // alert(err);
      },
    });
  },
  pastResult: function ({ currentHash, id, date }) {
    $.ajax({
      url: apiUrl + "/data/pastResult.json",
      data: {
        game: currentHash,
        id,
        date,
      },
      success: function (data) {
        renderPastResult(data);
      },
      error: function (err) {
        // alert(err);
      },
    });
  },
};

var countdown = function ({ selector, seconds, dark, isModal }) {
  var digit = `
    <div class="digit-wrapper">
      <span>9</span>
      <span>8</span>
      <span>7</span>
      <span>6</span>
      <span>5</span>
      <span>4</span>
      <span>3</span>
      <span>2</span>
      <span>1</span>
      <span>0</span>
    </div>
  `;

  var countdownDOM = `
    <div class="countdown-timer ${dark ? "dark" : ""}">
      <div class="countdown-timer-item minute-1">
        ${digit}
      </div>
      <div class="countdown-timer-item minute-2">
        ${digit}
      </div>
      <span>:</span>
      <div class="countdown-timer-item seconds-1">
        ${digit}
      </div>
      <div class="countdown-timer-item seconds-2">
        ${digit}
      </div>
    </div>
  `;
  $(selector).html(countdownDOM);

  var lotteryType = selector.closest(".result-item").data("lottery-type");
  var type = selector.closest(".result-item").data("type");

  // for timer inside modal (different structure)
  if (selector.attr("id") === "sidebar-time") {
    lotteryType = selector
      .closest(".modal-content")
      .find(".result-item")
      .data("lottery-type");
    type = selector.closest(".modal-content").find(".result-item").data("type");
  }

  // console.log(type, lotteryType);

  var countdownInterval;

  var intervalFunction = function () {
    seconds--;
    const formatted = moment.utc(seconds * 1000).format("mm:ss");
    const minute1 = formatted[0];
    const minute2 = formatted[1];
    const second1 = formatted[3];
    const second2 = formatted[4];

    selector
      .find(".minute-1 .digit-wrapper")
      .css("transform", `translateY(-${9 - minute1}0%)`)
      .css("transition-duration", `${minute1 === "9" ? 200 : 500}ms`);
    selector
      .find(".minute-2 .digit-wrapper")
      .css("transform", `translateY(-${9 - minute2}0%)`)
      .css("transition-duration", `${minute2 === "9" ? 200 : 500}ms`);
    selector
      .find(".seconds-1 .digit-wrapper")
      .css("transform", `translateY(-${9 - second1}0%)`)
      .css("transition-duration", `${second1 === "9" ? 200 : 500}ms`);
    selector
      .find(".seconds-2 .digit-wrapper")
      .css("transform", `translateY(-${9 - second2}0%)`)
      .css("transition-duration", `${second2 === "9" ? 200 : 500}ms`);

    if (seconds < 0) {
      if (isModal) {
        clearInterval(modalInterval);
      } else {
        clearInterval(countdownInterval);
      }

      /**
       * After finish countdown,
       * rerender that current item
       */
      api.result({
        currentHash: `${type}-${lotteryType}`,
        target: !isModal && selector.closest(".result-item"),
      });
    }
  };

  if (isModal) {
    modalInterval = setInterval(intervalFunction, 1000);
  } else {
    countdownInterval = setInterval(intervalFunction, 1000);
  }
};

function renderModal(data, currentHash) {
  var hashArr = currentHash.split("-");
  var lotteryType = data.lotteryType;
  // var time = hashArr[1];
  // var min = hashArr[2];
  var currentDate = util.getUrlParameter("date");

  var id = data.id;
  var type = data.type;
  var drawTime = data.drawTime;
  var nextDrawTime = data.nextDrawTime;
  var serverTime = data.serverTime;
  var date = data.date;
  var result = data.result;
  var headTail = data.headTail;

  var title = `${type.charAt(0).toUpperCase() + type.slice(1)} ${lotteryType
    .split("-")
    .map(function (name) {
      return `${name.charAt(0).toUpperCase() + name.slice(1)}`;
    })
    .join(" ")}`;

  clearInterval(modalInterval);
  var modalStructure = renderStructure.renderModalStructure({
    animate: isModalOpen ? false : true,
    type: type,
    lotteryType: lotteryType,
    currentDate: currentDate,
    lotteryName: title,
    id: id,
    nextDrawTime: nextDrawTime,
    date: date,
    result: result,
    headTail: headTail,
  });
  $("#modal").html(modalStructure);
  initDatePicker();
  renderLanguagePack();

  $("#modal .countdown").on("click", ".icon-speaker", function (e) {
    $(this).toggleClass("mute");
  });

  var countdownWrapper = $("#modal .time");

  var endTime = nextDrawTime;
  var startTime = serverTime;
  var seconds = endTime - startTime;

  countdown({
    selector: countdownWrapper,
    seconds,
    dark: true,
    isModal: true,
  });

  api.pastResult({ currentHash, id, date });

  isModalOpen = true;
  util.disableScroll();
}

function renderSingleItem(lottery, target) {
  target.empty();
  // data.forEach(function(lottery) {
  var type = lottery.type;
  var lotteryType = lottery.lotteryType;
  var lotteryId = lottery.id;
  var lotteryDate = lottery.date;
  var lotteryDrawTime = lottery.drawTime;
  var lotteryNextDrawTime = lottery.nextDrawTime;
  var serverTime = lottery.serverTime;
  var lotteryResult = lottery.result;

  var title = `${type.charAt(0).toUpperCase() + type.slice(1)} ${lotteryType
    .split("-")
    .map(function (name) {
      return `${name.charAt(0).toUpperCase() + name.slice(1)}`;
    })
    .join(" ")} Lotto`;

  if (lotteryType === "5-min") {
    var bigResultStructure = renderStructure.renderBigResultItem({
      title: title,
      type: type,
      lotteryType: lotteryType,
      lotteryId: lotteryId,
      lotteryDate: lotteryDate,
      lotteryDrawTime: lotteryDrawTime,
      lotteryNextDrawTime: lotteryNextDrawTime,
      serverTime: serverTime,
      lotteryResult: lotteryResult,
    });

    $(
      `.lottery-content.${type}ern .main-item-wrapper`
    )
      .empty()
      .html(bigResultStructure);

    var timeCounterSelector = $(
      `.lottery-content.${type}ern .main-item-wrapper .result-item[data-lottery-type="${lotteryType}"] .time-counter`
    );

    var endTime = lotteryNextDrawTime;
    var startTime = serverTime;
    var seconds = endTime - startTime;

    countdown({
      selector: timeCounterSelector,
      seconds,
    });
  } else {
    var smallResultStructure = renderStructure.renderSmallResultItem({
      title: title,
      type: type,
      lotteryType: lotteryType,
      lotteryId: lotteryId,
      lotteryDate: lotteryDate,
      lotteryDrawTime: lotteryDrawTime,
      lotteryNextDrawTime: lotteryNextDrawTime,
      serverTime: serverTime,
      lotteryResult: lotteryResult,
    });
  }

  target.append($(smallResultStructure).html());

  var timeCounterSelector = target.find(".time-counter");

  var endTime = lotteryNextDrawTime;
  var startTime = serverTime;
  var seconds = endTime - startTime;

  countdown({
    selector: timeCounterSelector,
    seconds,
  });
  // });
}

function renderHome(data) {
  $(".lottery-content .big-wrapper").empty();
  $(".top-header .today-date").empty();

  data.forEach(function (lottery) {
    var type = lottery.type;
    var lotteryType = lottery.lotteryType;
    var lotteryId = lottery.id;
    var lotteryDate = lottery.date;
    var lotteryDrawTime = lottery.drawTime;
    var lotteryNextDrawTime = lottery.nextDrawTime;
    var serverTime = lottery.serverTime;
    var lotteryResult = lottery.result;
    var title = `${type.charAt(0).toUpperCase() + type.slice(1)} ${lotteryType
      .split("-")
      .map(function (name) {
        return `${name.charAt(0).toUpperCase() + name.slice(1)}`;
      })
      .join(" ")} Lotto`;

    $(".top-header .today-date").text(lotteryDate);

    if (lotteryType === "5-min") {
      var bigResultStructure = renderStructure.renderBigResultItem({
        title: title,
        type: type,
        lotteryType: lotteryType,
        lotteryId: lotteryId,
        lotteryDate: lotteryDate,
        lotteryDrawTime: lotteryDrawTime,
        lotteryNextDrawTime: lotteryNextDrawTime,
        serverTime: serverTime,
        lotteryResult: lotteryResult,
      });

      $(`.lottery-content.${type}ern .main-item-wrapper`)
        .empty()
        .html(bigResultStructure);

      var timeCounterSelector = $(
        `.lottery-content.${type}ern .main-item-wrapper .result-item[data-lottery-type="${lotteryType}"] .time-counter`
      );

      // var startDate = new Date(serverTime * 1000);
      // var endDate = new Date(lotteryNextDrawTime * 1000);

      // var seconds = Math.floor(
      //   (endDate.getTime() - startDate.getTime()) / 1000
      // );

      var endTime = lotteryNextDrawTime;
      var startTime = serverTime;
      var seconds = endTime - startTime;
      // var seconds = resolution / 1000 / 60 / 60;
      // console.log(endTime, startTime, seconds);

      countdown({
        selector: timeCounterSelector,
        seconds,
      });
    } else {
      var smallResultStructure = renderStructure.renderSmallResultItem({
        title: title,
        type: type,
        lotteryType: lotteryType,
        lotteryId: lotteryId,
        lotteryDate: lotteryDate,
        lotteryDrawTime: lotteryDrawTime,
        lotteryNextDrawTime: lotteryNextDrawTime,
        serverTime: serverTime,
        lotteryResult: lotteryResult,
      });

      $(`.lottery-content.${type}ern .big-wrapper`).append(
        smallResultStructure
      );

      var timeCounterSelector = $(
        `.lottery-content.${type}ern .big-wrapper .result-item[data-lottery-type="${lotteryType}"] .time-counter`
      );

      var endTime = lotteryNextDrawTime;
      var startTime = serverTime;
      var seconds = endTime - startTime;

      // console.log(type, lotteryType, startTime, endTime);
      // var seconds = resolution / 60 / 60;

      countdown({
        selector: timeCounterSelector,
        seconds,
      });
    }
  });

  renderLanguagePack();
}

function renderPastResult(data) {
  var modalSidebar = renderStructure.renderModalPastResult(data);
  $("#modal .sidebar .sidebar-content").html(modalSidebar);

  // var currentId = util.getUrlParameter("id");

  // console.log(
  //   "container top",
  //   $("#modal .sidebar .sidebar-content").offset().top
  // );
  //
  // console.log("item top", $(".sidebar-lottery-item.active").offset().top);

  $("#modal .sidebar .sidebar-content").animate(
    {
      scrollTop:
        $(".sidebar-lottery-item.active").offset().top -
        $("#modal .sidebar .sidebar-content").offset().top,
    },
    0
  );
}

function renderIfHash() {
  // var currentHash = location.hash.replace("#", "");
  var currentHash = util.getUrlParameter("game");
  $(".datepicker-container").remove();

  var id = util.getUrlParameter("id");
  var date = util.getUrlParameter("date");

  if (currentHash) {
    api.result({ currentHash, id, date });
  }
}

function bindMustacheEvent() {
  $(document).on("click", ".modal .close", function (e) {
    e.preventDefault();
    var currModal = $(e.currentTarget).parents(".modal");
    currModal.addClass("fade-out").on("animationend", function () {
      currModal.remove();

      isModalOpen = false;
      window.location.hash = "";

      /**
       * Clear countdown interval
       */
      clearInterval(modalInterval);
      util.enableScroll();
    });
  });
}

function initDatePicker() {
  $('[data-toggle="datepicker"]:not(.hasDatepicker)').datepicker({
    inline: true,
    format: "dd-mm-yyyy",
    endDate: new Date(),
    pick: function (select) {
      var selectedDate = select.date;
      var formattedDate = moment(selectedDate).format("DD-MM-YYYY");
      var currentGame = util.getUrlParameter("game");
      var currentId = util.getUrlParameter("id");

      $('[data-toggle="datepicker"]').datepicker("hide");
      location.hash = `game=${currentGame}${
        currentId ? "&id=" + currentGame : ""
      }&date=${formattedDate}`;
      // $(this)
      //   .find(".span-date")
      //   .text(formattedDate);
    },
    filter: function (date, view) {
      // if (view === "day") {
      //   if (date.getDay() === 0 || date.getDay() === 4) {
      //     return false; // Disable all suns & thurs
      //   }
      // }
    },
  });
  $('[data-toggle="datepicker"]').datepicker("hide");

  $(".date-picker-wrapper").on("click", function () {
    if ($(this).find(".datepicker-container").hasClass("datepicker-hide")) {
      $('[data-toggle="datepicker"]').datepicker("show");
    } else {
      $('[data-toggle="datepicker"]').datepicker("hide");
    }
  });
}

function renderLanguagePack() {
  var pathname = window.location.pathname;
  var language = "en";
  $("#languageSelector").val("/en");

  if (pathname.includes("/vi")) {
    language = "vi";
    $(".top-header-wrapper-img").attr("src", "img/header-logo-vi.png");
    $(".footer-img").attr("src", "img/footer-leopard-vi.png");
    $("#languageSelector").val("/vi");
  }
  else if (pathname.includes("/th")) {
    language = "th";
    $(".top-header-wrapper-img").attr("src", "img/header-logo-th.png");
    $(".footer-img").attr("src", "img/footer-leopard-th.png");
    $("#languageSelector").val("/th");
  }
  else if (pathname.includes("/en")) {
    language = "en";
    $(".top-header-wrapper-img").attr("src", "img/header-logo.png");
    $(".footer-img").attr("src", "img/footer-leopard.png");
    $("#languageSelector").val("/en");
  }

  $.ajax("languages/" + language + ".json").done(function (data) {
    const languagePack = data;
    $("[data-lang-text]").each(function (index, value) {
      const key = $(value).data("lang-text");
      const currentLanguage = languagePack[key];
      $(value).text(currentLanguage);
    });
  });
}

$(document).ready(function () {
  api.lastResult();
  util.hashDetection();
  bindMustacheEvent();
  renderLanguagePack();

  window.addEventListener("scroll", () => {
    document.documentElement.style.setProperty(
      "--scroll-y",
      `${window.scrollY}px`
    );
  });
});
